<template>
  <div class="container">
    <md-card class="dash-content">
      <PageHeader title="Orders Management" />
      <div class="loader">
        <Wave v-if="loading || updating" width="50px" color="#d9534f" />
      </div>
      <md-table
        v-model="orders.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <md-button
              :disabled="exporting"
              class="md-primary button-icon"
              @click="exportExcel"
            >
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button>
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                md-menu-trigger
                class="md-primary button-icon"
                disabled
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item>Approved</md-menu-item>
                <md-menu-item>Pending</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="S/N" md-numeric class="text-center">{{
            item.id
          }}</md-table-cell>
          <md-table-cell md-label="Code">{{ item.order_code }}</md-table-cell>
          <md-table-cell md-label="Subtotal">{{
            item.subtotal | formatMoney
          }}</md-table-cell>
          <md-table-cell md-label="Total">{{
            item.total | formatMoney
          }}</md-table-cell>
          <md-table-cell md-label="Payment Status">{{
            item.payment_status
          }}</md-table-cell>
          <md-table-cell md-label="Order Status">{{
            item.order_status.title
          }}</md-table-cell>

          <md-table-cell md-label="Created At">{{
            item.created_at | formatDate
          }}</md-table-cell>
          <md-table-cell md-label="Action">
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                class="md-primary button-icon"
                md-menu-trigger
                :disabled="updating"
              >
                Menu
                <img src="@/assets/svg/caret.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item @click="setOrder(item)"
                  >Show Details</md-menu-item
                >
                <div v-if="hasPermission">
                  <md-menu-item
                    v-if="['paid'].includes(item.payment_status)"
                    @click="updateStatus(item.id, 'unpaid', 'payment_status')"
                    >Mark as unpaid</md-menu-item
                  >
                  <md-menu-item
                    v-else
                    @click="updateStatus(item.id, 'paid', 'payment_status')"
                    >Mark as paid</md-menu-item
                  >

                  <md-menu-item @click="setShowStatus(item.id)"
                    >Update Status</md-menu-item
                  >
                </div>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="orders.mdPage"
        :records="orders.mdCount"
        :per-page="orders.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title>Order Details</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="order">
        <div class="item">
          <strong>Sub Total</strong>
          <span> {{ order.subtotal | formatMoney }}</span>
        </div>
        <div class="item">
          <strong>Total</strong>
          <span> {{ order.total | formatMoney }}</span>
        </div>
        <div class="item">
          <strong>Payment Status</strong>
          <div
            class="badge"
            :class="{ success: order.payment_status == 'paid' }"
          >
            {{ order.payment_status }}
          </div>
        </div>
        <div class="item">
          <strong>Order Code</strong>
          <span> {{ order.order_code }}</span>
        </div>
        <div class="item">
          <strong>Order Status</strong>
          <span> {{ order.order_status.title }}</span>
        </div>
        <div class="item">
          <strong>Date Created</strong>
          <span> {{ order.created_at | formatDate }}</span>
        </div>
        <hr />
        <div>
          <h4>Items</h4>
        </div>

        <div v-if="order.items.length">
          <div v-for="item in order.items" :key="item.id">
            <div class="d-flex" style="justify-content: space-between">
              <div>{{ item.title }}</div>
              <div>{{ item.quantity }} X {{ item.price }}</div>
            </div>
          </div>
        </div>
        <div v-else>Empty Items</div>

        <hr />
        <div>
          <h4>Shipping information</h4>
        </div>
        <div class="item">
          <strong>Shipping address</strong>
          <span>{{ order.shipping_address }} </span>
        </div>
        <div class="item">
          <strong>Shipping City</strong>
          <span> {{ order.shipping_city }}</span>
        </div>
        <div class="item">
          <strong>Shipping First Name</strong>
          <span> {{ order.shipping_first_name }}</span>
        </div>

        <div class="item">
          <strong>Shipping Last Name</strong>
          <span> {{ order.shipping_last_name }}</span>
        </div>
        <div class="item">
          <strong>Shipping Phone Number</strong>
          <span> {{ order.shipping_phone }} </span>
        </div>

        <div class="item">
          <strong>Shipping Price</strong>
          <span> {{ order.shipping_price | formatMoney }}</span>
        </div>

        <div class="item">
          <strong>Order status</strong>
          <span> {{ order.order_status.titles }}</span>
        </div>

        <div class="item">
          <strong>Date Created</strong>
          <span> {{ order.created_at | formatDate }}</span>
        </div>

        <div class="item">
          <strong>Order Code</strong>
          <span> {{ order.order_code }}</span>
        </div>

        <hr />
        <div>
          <h4>Owner's information</h4>
        </div>
        <div class="item">
          <strong>Fullname</strong>
          <span> {{ order.user.name }}</span>
        </div>

        <div class="item">
          <strong>Email Address</strong>
          <span> {{ order.user.email }}</span>
        </div>

        <div class="item">
          <strong>Phone Number</strong>
          <a :href="`tel:${order.user.phone_ext + order.user.phone}`">
            {{ order.user.phone_ext + order.user.phone }}</a
          >
        </div>
      </div>
    </md-dialog>

    <md-dialog :md-active.sync="showStatus">
      <md-dialog-title>Update Order Status</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="showStatus = false">close</i>
      </div>

      <div class="modal-contain" v-if="metadata">
        <small v-if="fetching" class="loading">Fetching...</small>
        <md-field>
          <label for="parent_id">Order Status</label>
          <md-select
            v-model="form.order_status"
            name="parent_id"
            id="parent_id"
          >
            <md-option value="" disabled>Select Order Status</md-option>
            <md-option
              v-for="(status, id) in metadata.order_statuses"
              :key="status"
              :value="id"
              >{{ status }}</md-option
            >
          </md-select>
        </md-field>
        <br />
        <mdc-button
          :loading="updating"
          @click.native="updateStatus(metadata.order.id, _, 'order_status')"
          >Update</mdc-button
        >
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { PageHeader, Wave } from "@/components";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("product");

export default {
  name: "Orders",
  components: {
    PageHeader,
    Wave,
  },
  data: () => ({
    search: null,
    show: false,
    fetching: false,
    updating: false,
    exporting: false,
    time: null,
    order: null,
    showStatus: false,
    metadata: true,
    form: {
      shipping_status: "",
    },
  }),
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },
  methods: {
    ...mapActions(["getOrders", "updateOrderStatus", "getOrderMetadata"]),
    async exportExcel() {
      this.exporting = true;
      await this.$store.dispatch("auth/export", { model: "order" });
      this.exporting = false;
    },
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getOrders(data);
    },
    async submitSearch() {
      await this.getOrders({ keyword: this.search });
    },
    async updateStatus(id, status, field) {
      let data = { id };
      if (field == "payment_status") {
        data.payment_status = status;
      }
      if (field == "order_status") {
        data.order_status_id = this.form.order_status;
      }

      try {
        this.updating = true;
        await this.updateOrderStatus(data);
        this.updating = false;
        this.toast("success", "Order Status Updated ");
        if (field == "order_status") {
          this.showStatus = false;
          this.getOrders();
        }
      } catch (err) {
        this.updating = false;
        this.toast("error", "Opps, Something went wrong");
      }
    },
    async setShowStatus(id) {
      this.showStatus = true;
      this.fetching = true;
      let metadata = await this.getOrderMetadata(id);
      this.fetching = false;
      this.metadata = metadata;
    },
    setOrder(order) {
      this.show = true;
      this.order = order;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
  },
  created() {
    this.searched = this.users;
  },
  mounted() {
    this.getOrders({});
  },
  computed: {
    ...mapGetters(["loading", "orders"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
  },
};
</script>

<style lang="scss" scoped>
// .md-field {
//   max-width: 500px;
// }
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.badge {
  background: #f3f3f3;

  &.success {
    background: #5cb85c;
  }

  &.danger {
    background: #d9534f;
  }
}

.modal-contain {
  max-width: 100%;
  width: 600px;
  padding: 20px;
  overflow-y: auto;

  .item {
    display: flex;
    gap: 20px;
    padding: 10px 10px;
    margin: 5px 0px;

    &:nth-child(odd) {
      background: #00ff1f0d;
    }
  }

  .media {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .display {
      height: 150px;
      width: 150px;
      background: #ccc;
      border-radius: 5px;
      img {
        border-radius: 5px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
.loader {
  position: absolute;
  top: 24px;
  right: 30px;
}
</style>
